import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  interestedCategories: null,
  userImage: null,
  userName: "",
  loading: false,
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.interestedCategories =
        action.payload.user_extra_details.interested_categories;
    },
    loginFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    logOff: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },
    updateUserImage: (state, action) => {
      state.loading = false;
      state.userImage = action.payload;
    },
    updateUserName: (state, action) => {
      state.loading = false;
      state.userName = action.payload;
    },
    updateInterestedCategories: (state, action) => {
      state.loading = false;
      state.interestedCategories = action.payload;
    },
    updateEmailVerified: (state, action) => {
      state.loading = false;
      state.currentUser.user_extra_details.email_verified = action.payload;
    },
    updateCountry: (state, action) => {
      state.loading = false;
      state.currentUser.user_extra_details.country = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logOff,
  updateUserImage,
  updateUserName,
  updateInterestedCategories,
  updateEmailVerified,
  updateCountry
} = userSlice.actions;

export default userSlice.reducer;
