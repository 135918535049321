import { useState, useRef, useEffect, useCallback } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getAutocompleteKeywords, getCategory, getNotification, getUserTokenStatus, logout } from '../../lib/api';
import Notification from '../Modals/Notification';
import UserPanel from '../Modals/UserPanel';
import Button from '../UI/Button';
import logo from '../../assets/images/logoColor.svg';
import userPlaceholder from '../../assets/images/userPlaceholder.jpg'
import { logOff } from '../../redux/userSlice';
import { clearAuthorList } from '../../redux/authorSlice';
import { useModal } from '../../store/ModalContext';
import { useIdleTimer } from 'react-idle-timer';
import { Helmet } from "react-helmet";
import { updateNotificationList, updateUnReadCount } from '../../redux/notificationSlice';
import { updateCategoryList, clearCategoryList } from '../../redux/categorySlice';
import { clearHighlight } from '../../redux/highlightSlice';
import { clearPeerSearch } from '../../redux/peersearchSlice';
import { clearFooterLink } from '../../redux/footerlinkSlice';


const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { openLoginModal, openRegisterModal, EncryptionData, DecryptionData, bodyBodyOverflow } = useModal();

  const { currentUser, userImage, userName } = useSelector((state) => state.user);
  const { unReadNotification } = useSelector((state) => state.notification);
  const { categoryList } = useSelector((state) => state.category);
  const userMenuRef = useRef(null);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
  const [isUserPanelOpen, setIsUserPanelOpen] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('');
  const [autoComplete, setAutoComplete] = useState('hidden');
  const [keywordsList, setKeywordsList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchClose, setSearchClose] = useState('hidden');
  const [leftscroll, setLeftScroll] = useState('');
  const [rightscroll, setRightScroll] = useState('');
  const notificationPanelRef = useRef(null);
  const userPanelRef = useRef(null);
  const loginPopupRef = useRef(null);
  const searchTextBox = useRef();
  const autosuggRef = useRef();

  // Function to toggle the popup
  const toggleNotificationPanel = () => {
    setIsNotificationPanelOpen(!isNotificationPanelOpen);
  };

  const toggleUserPanel = () => {
    setIsUserPanelOpen(!isUserPanelOpen);
  };

  const history = useHistory();
  const categoryRef = useRef();

  let shadowClass = '';

  if (location.pathname === '/specialization' || location.pathname !== "/allTeamMembers" || location.pathname !== "/author-details") {
    shadowClass = 'shadow-activeShadow';
  }

  const handlerCategorySearch = (event) => {
    if (event.target.id === '') {
      event.target.id = 0;
    }
    var textArray = event.target.textContent.split(" ");
    var data;
    if (textArray[1] === 'Management') {
      data = event.target.id + '##' + textArray[0];
    } else {
      data = event.target.id + '##' + event.target.textContent;
    }
    setActiveCategory(event.target.id);
    history.replace('/search-result/' + EncryptionData(data));
  }

  const handleSearchClick = () => {
    getAutocompleteKeywords()
      .then((response) => {
        setKeywordsList(response.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleNav = (speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      categoryRef.current.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        if (categoryRef.current.scrollLeft === categoryRef.current.scrollLeftMax) {
          setRightScroll('disabled');
          setLeftScroll('');
        } else {
          setRightScroll('');
          setLeftScroll('');
        }
        if (categoryRef.current.scrollLeft > 10) {
          setLeftScroll('');
        } else {
          setLeftScroll('disabled');
        }
        clearInterval(slideTimer);
      }
    }, speed);
  }

  const handlerSearchAll = () => {
    const textbox_value = searchTextBox.current.value.toLowerCase();
    if (textbox_value && textbox_value.length > 2) {
      setAutoComplete('hidden');
      history.push('/searchall-result/' + EncryptionData(textbox_value));
    }
  }

  const clearSearchText = () => {
    setAutoComplete('hidden');
    setSearchClose('hidden');
    searchTextBox.current.value = '';
  }

  const sortKeywordsByRelevance = (keywordsList, searchText) => {
    return keywordsList.sort((a, b) => {
      const startsWithSearchTextA = a.startsWith(searchText);
      const startsWithSearchTextB = b.startsWith(searchText);
      if (startsWithSearchTextA && startsWithSearchTextB) {
        return a.length - b.length;
      }
      if (startsWithSearchTextA) {
        return -1;
      }
      if (startsWithSearchTextB) {
        return 1;
      }
      return a.localeCompare(b);
    });
  }

  const handleSearchOnKeyDown = (e) => {
    const textbox_value = searchTextBox.current.value.toLowerCase();
    if (e.keyCode === 32 && !textbox_value.length)
      e.preventDefault();
    if (textbox_value && textbox_value.length > 2) {
      if (keywordsList && keywordsList.length > 0) {
        const suggestionArray = keywordsList.filter(str => str.includes(textbox_value));
        sortKeywordsByRelevance(suggestionArray, textbox_value)
        setSuggestionList(suggestionArray);
      }
      setAutoComplete('block');
      setSearchClose('block');
      if (e.key === 'Enter' || e.keyCode === 13) {
        setAutoComplete('hidden');
        searchTextBox.current.value = textbox_value;
        history.push('/searchall-result/' + EncryptionData(textbox_value));
      }
    } else {
      setAutoComplete('hidden');
      setSearchClose('hidden');
    }
  }

  const handlesuggestionClick = (value) => {
    if (value && value.length > 2) {
      setAutoComplete('hidden');
      searchTextBox.current.value = value;
      history.push('/searchall-result/' + EncryptionData(value));
    }
  }

  const handleMiniSearch = () => {
    history.push('/minisearch');
  }

  const handleLogin = () => {
    openLoginModal();
  }

  const handleRegister = () => {
    openRegisterModal();
  }

  const onIdle = () => {
    if (currentUser !== null) {
      signOut();
    }
  }

  useIdleTimer({
    onIdle,
    timeout: 100 * 60 * 1000, //30 minute idle timeout
  })

  const signOut = () => {
    logout(currentUser.logout_token, currentUser.access_token, currentUser.csrf_token)
      .then((response) => {
        dispatch(logOff());
        dispatch(clearCategoryList());
        dispatch(clearAuthorList());
        dispatch(clearHighlight());
        dispatch(clearPeerSearch());
        dispatch(clearFooterLink());
        history.push('/');
      })
      .catch((error) => {
        //error handling
      })
  }

  useEffect(() => {
    let path = location.pathname.split("/");
    if (path[1] === 'search-result') {
      const categoryData = DecryptionData(path[2]).split("##");
      setActiveCategory(categoryData[0]);
    } else {
      setActiveCategory('');
    }

    if (path[1] !== 'searchall-result') {
      searchTextBox.current.value = '';
      setAutoComplete('hidden');
      setSearchClose('hidden');
    }

    if (path[1] !== 'searchall-result' && path[1] !== 'minisearch') {
      localStorage.setItem('minisearch_value', '');
    }

    if (path[1] === 'resetpassword' || path[1] === 'useractivation' || path[1] === 'resetnewpassword') {
      document.body.classList.add('resetPassword');
    } else {
      document.body.classList.remove('resetPassword');
    }

    {
      Object.entries(localStorage).map(([key, valueJSON]) => {
      })
    }

    function handleClickOutside(event) {
      if (
        notificationPanelRef.current &&
        !notificationPanelRef.current.contains(event.target)
      ) {
        setIsNotificationPanelOpen(false);
      }
      if (
        userPanelRef.current &&
        !userPanelRef.current.contains(event.target)
      ) {
        setIsUserPanelOpen(false);
      }
      if (
        loginPopupRef.current &&
        !loginPopupRef.current.contains(event.target)
      ) {
        setIsLoginPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationPanelRef, userPanelRef, loginPopupRef, location.pathname, DecryptionData]);

  const toggleLoginPopup = () => {
    setIsLoginPopupOpen(!isLoginPopupOpen);
  };

  const getNotificationList = useCallback(() => {
    getNotification(currentUser)
      .then((response) => {
        dispatch(updateNotificationList(response));
        const unreadNotifications = response.filter(notification => notification.isread === "False").length;
        dispatch(updateUnReadCount(unreadNotifications))
      })
      .catch((error) => {
        console.log(error);
      })
  }, [currentUser])

  useEffect(() => {
    if (categoryList === null || categoryList.length === 0) {
      categorysendRequest();
    }
    if (currentUser) {
      getNotificationList();
    }
  }, [categoryList, getNotificationList, currentUser]);

  const categorysendRequest = () => {
    getCategory()
      .then((response) => {
        dispatch(updateCategoryList(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const closeDropdown = e => {
      if (autosuggRef.current) {
        if (!autosuggRef.current.contains(e.target)) {
          setAutoComplete('hidden');
        }
      }
    }
    document.body.addEventListener('click', closeDropdown)
    return () => document.body.removeEventListener('click', closeDropdown)
  }, []);

  const userActiveStatus = (currentUser) => {
    getUserTokenStatus(currentUser)
      .then((response) => {
        if (response === 0) {
          signOut();
        } else {
          bodyBodyOverflow();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const clearReduxData = (currentUser) => {
    dispatch(clearCategoryList());
    dispatch(clearAuthorList());
    dispatch(clearHighlight());
    dispatch(clearPeerSearch());
    dispatch(clearFooterLink());
  }

  var delay = 60 * 60 * 1000;
  setInterval(function () {
    if (currentUser) {
      userActiveStatus(currentUser);
    }
    clearReduxData();
  }, delay);

  return (
    <header
      className={`flex flex-col gap-3 headerWrapper px-5 sm:px-5 md:px-5 lg:px-14 py-3 sticky top-0 z-[100] bg-white ${shadowClass}`}
    >
      <div className="headerItems flex  items-center flex-nowrap *:shrink *:grow md:justify-normal justify-between sm:justify-between lg:justify-normal gap-6">
        <Helmet>
          (<title>{`Masters Video Library`}</title>)
        </Helmet>
        <div className="logoHolder !shrink-0 xl:!shrink-0 2xl:!shrink-0 md:basis-[25%]  lg:basis-[20%] xl:basis-[30%] !grow-0">
          <NavLink to="/">
            <img src={logo} alt="mastersvideolibraryLogo" className="w-[180px] sm:w-[220px] md:w-[260px] lg:w-[320px]" />
          </NavLink>
        </div>
        <div className="searchHolder h-10 basis-auto sm:basis-auto md:basis-auto lg:basis-[40%] rounded-lg justify-between border border-[#C2CED7] bg-white hidden sm:hidden md:flex shrink grow-0 relative group relative group" ref={autosuggRef}>
          <input
            className="bg-white rounded-tl-lg rounded-bl-lg grow px-[14px] leading-6 text-base font-medium placeholder:text-[#717375] outline-none shadow-none"
            type="text"
            name="search"
            autoComplete="off"
            placeholder="Search"
            ref={searchTextBox}
            onKeyDown={handleSearchOnKeyDown}
            onClick={handleSearchClick}
          />
          <div className={`closeIconHolder static h-full flex items-center pr-4 cursor-pointer ${searchClose}`} onClick={clearSearchText}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
              <path d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z" fill="#717375" stroke="#717375"></path><path d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z" fill="#717375" stroke="#717375"></path>
            </svg>
          </div>
          <button
            className="searchBtn px-[14px] bg-[#F9F9F9] rounded-tr-lg rounded-br-lg cursor-pointer border-l border-[#C2CED7]"
            onClick={handlerSearchAll}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 17.1609 17.1609 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z"
                fill="#717375"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4697 19.4697C19.7626 19.1768 20.2374 19.1768 20.5303 19.4697L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L19.4697 20.5303C19.1768 20.2374 19.1768 19.7626 19.4697 19.4697Z"
                fill="#717375"
              ></path>
            </svg>
          </button>
          <div className={`searchDropdownHolder absolute mt-1 top-full max-h-[260px] overflow-auto rounded-lg border border-[#C2CED7] bg-white left-0 right-0 z-50 ${autoComplete}`}>
            {suggestionList.length > 0 ? (
              <div className='flex flex-col'>
                {suggestionList.map((details) =>
                  <div className='searchResultItem px-[14px] py-[7px] hover:bg-[#F0F0F0] cursor-pointer' key={details} onClick={() => handlesuggestionClick(details)}>
                    <div className='flex gap-2 items-center'>
                      <div className='searchIcon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M8.26369 13.9999C11.8579 13.9999 14.7715 11.1644 14.7715 7.66659C14.7715 4.16878 11.8579 1.33325 8.26369 1.33325C4.66952 1.33325 1.75586 4.16878 1.75586 7.66659C1.75586 11.1644 4.66952 13.9999 8.26369 13.9999Z" stroke="#717375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M15.4565 14.6666L14.0864 13.3333" stroke="#717375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <p className='searchText text-black text-sm  leading-7 sm:text-base sm:leading-8 truncate '>{details}</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='flex flex-col px-[14px] py-[7px]'>--- No results found ---</div>
            )}
          </div>
        </div>
        <div className={currentUser ? "headerOptions md:basis-[20%]  lg:basis-[20%] xl:basis-[30%] place-content-end flex items-center relative basis-auto" : "headerOptions md:basis-[20%]  lg:basis-[20%] xl:basis-[30%] place-content-end flex items-center relative basis-auto !grow-0"}>
          <div className="flex gap-4  items-stretch">
            <div className="languageSelector gap-2 items-center hidden">
              <div className="languageIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                    fill="#717375"
                  />
                  <path
                    d="M9.00023 21.75H8.00023C7.59023 21.75 7.25023 21.41 7.25023 21C7.25023 20.59 7.57023 20.26 7.98023 20.25C6.41023 14.89 6.41023 9.11 7.98023 3.75C7.57023 3.74 7.25023 3.41 7.25023 3C7.25023 2.59 7.59023 2.25 8.00023 2.25H9.00023C9.24023 2.25 9.47023 2.37 9.61023 2.56C9.75023 2.76 9.79023 3.01 9.71023 3.24C7.83023 8.89 7.83023 15.11 9.71023 20.77C9.79023 21 9.75023 21.25 9.61023 21.45C9.47023 21.63 9.24023 21.75 9.00023 21.75Z"
                    fill="#717375"
                  />
                  <path
                    d="M15.0004 21.75C14.9204 21.75 14.8404 21.74 14.7604 21.71C14.3704 21.58 14.1504 21.15 14.2904 20.76C16.1704 15.11 16.1704 8.89 14.2904 3.23C14.1604 2.84 14.3704 2.41 14.7604 2.28C15.1604 2.15 15.5804 2.36 15.7104 2.75C17.7004 8.71 17.7004 15.27 15.7104 21.22C15.6104 21.55 15.3104 21.75 15.0004 21.75Z"
                    fill="#717375"
                  />
                  <path
                    d="M12 17.2C9.21 17.2 6.43 16.81 3.75 16.02C3.74 16.42 3.41 16.75 3 16.75C2.59 16.75 2.25 16.41 2.25 16V15C2.25 14.76 2.37 14.53 2.56 14.39C2.76 14.25 3.01 14.21 3.24 14.29C8.89 16.17 15.12 16.17 20.77 14.29C21 14.21 21.25 14.25 21.45 14.39C21.65 14.53 21.76 14.76 21.76 15V16C21.76 16.41 21.42 16.75 21.01 16.75C20.6 16.75 20.27 16.43 20.26 16.02C17.57 16.81 14.79 17.2 12 17.2Z"
                    fill="#717375"
                  />
                  <path
                    d="M20.9998 9.74999C20.9198 9.74999 20.8398 9.73999 20.7598 9.70999C15.1098 7.82999 8.87978 7.82999 3.22978 9.70999C2.82978 9.83999 2.40978 9.62999 2.27978 9.23999C2.15978 8.83999 2.36978 8.41999 2.75978 8.28999C8.71978 6.29999 15.2798 6.29999 21.2298 8.28999C21.6198 8.41999 21.8398 8.84999 21.6998 9.23999C21.6098 9.54999 21.3098 9.74999 20.9998 9.74999Z"
                    fill="#717375"
                  />
                </svg>
              </div>
              <div className="flex items-center gap-1">
                <div className="language text-primaryBlue font-normal leading-6 text-base">
                  EN
                </div>
                <div className="downArrow cursor-pointer">
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.8998 0H1.24913C0.382767 0 -0.073698 1.02665 0.506668 1.66989L4.53333 6.13278C4.94446 6.58844 5.66522 6.56968 6.05209 6.09326L9.67609 1.63037C10.2068 0.976811 9.7417 0 8.8998 0Z"
                      fill="#8594A6"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            {currentUser ? (
              <div className="notificationParent flex">
                <div
                  className={`notification flex self-center relative cursor-pointer group ${location.pathname.includes("notificationDetails") ? 'disabled' : ''}`}
                  onClick={toggleNotificationPanel}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0199 20.53C9.68987 20.53 7.35987 20.16 5.14987 19.42C4.30987 19.13 3.66987 18.54 3.38987 17.77C3.09987 17 3.19987 16.15 3.65987 15.39L4.80987 13.48C5.04987 13.08 5.26987 12.28 5.26987 11.81V8.91998C5.26987 5.19998 8.29987 2.16998 12.0199 2.16998C15.7399 2.16998 18.7699 5.19998 18.7699 8.91998V11.81C18.7699 12.27 18.9899 13.08 19.2299 13.49L20.3699 15.39C20.7999 16.11 20.8799 16.98 20.5899 17.77C20.2999 18.56 19.6699 19.16 18.8799 19.42C16.6799 20.16 14.3499 20.53 12.0199 20.53ZM12.0199 3.66998C9.12987 3.66998 6.76987 6.01998 6.76987 8.91998V11.81C6.76987 12.54 6.46987 13.62 6.09987 14.25L4.94987 16.16C4.72987 16.53 4.66987 16.92 4.79987 17.25C4.91987 17.59 5.21987 17.85 5.62987 17.99C9.80987 19.39 14.2399 19.39 18.4199 17.99C18.7799 17.87 19.0599 17.6 19.1899 17.24C19.3199 16.88 19.2899 16.49 19.0899 16.16L17.9399 14.25C17.5599 13.6 17.2699 12.53 17.2699 11.8V8.91998C17.2699 6.01998 14.9199 3.66998 12.0199 3.66998Z"
                        fill="#717375"
                      ></path>
                      <path
                        d="M13.8796 3.93999C13.8096 3.93999 13.7396 3.92999 13.6696 3.90999C13.3796 3.82999 13.0996 3.76999 12.8296 3.72999C11.9796 3.61999 11.1596 3.67999 10.3896 3.90999C10.1096 3.99999 9.80963 3.90999 9.61963 3.69999C9.42963 3.48999 9.36963 3.18999 9.47963 2.91999C9.88963 1.86999 10.8896 1.17999 12.0296 1.17999C13.1696 1.17999 14.1696 1.85999 14.5796 2.91999C14.6796 3.18999 14.6296 3.48999 14.4396 3.69999C14.2896 3.85999 14.0796 3.93999 13.8796 3.93999Z"
                        fill="#717375"
                      ></path>
                      <path
                        d="M12.0195 22.81C11.0295 22.81 10.0695 22.41 9.36953 21.71C8.66953 21.01 8.26953 20.05 8.26953 19.06H9.76953C9.76953 19.65 10.0095 20.23 10.4295 20.65C10.8495 21.07 11.4295 21.31 12.0195 21.31C13.2595 21.31 14.2695 20.3 14.2695 19.06H15.7695C15.7695 21.13 14.0895 22.81 12.0195 22.81Z"
                        fill="#717375"
                      ></path>
                    </svg>
                  </span>
                  <span className={`count bg-[red] rounded-full text-white size-4 absolute -top-1 right-0 flex text-xs items-center justify-center ${unReadNotification > 0 ? '' : 'hidden'}`}>
                    {unReadNotification}
                  </span>
                </div>

                {isNotificationPanelOpen && <div className="overlay-popup"></div>}

                {isNotificationPanelOpen && <Notification notificationRef={notificationPanelRef} isToggle={toggleNotificationPanel} handleShowFromNotification={() => false} />}
              </div>
            ) : (
              <></>
            )}
            <div className="searchIconForMinscreen flex self-center sm:flex md:hidden cursor-pointer" onClick={handleMiniSearch}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.58366 17.4998C13.9559 17.4998 17.5003 13.9554 17.5003 9.58317C17.5003 5.21092 13.9559 1.6665 9.58366 1.6665C5.2114 1.6665 1.66699 5.21092 1.66699 9.58317C1.66699 13.9554 5.2114 17.4998 9.58366 17.4998Z"
                  stroke="#717375"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M18.3337 18.3332L16.667 16.6665"
                  stroke="#717375"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
            {!currentUser && (
              <div className="profileIcon flex self-center sm:flex md:hidden" onClick={toggleLoginPopup}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99983 5.39844C8.71256 5.39844 7.68066 6.43677 7.68066 7.7176C7.68066 8.95399 8.64649 9.96207 9.87026 10.0261C9.95457 10.0193 10.0445 10.0186 10.1318 10.026C11.3503 9.96091 12.311 8.95673 12.319 7.71566C12.3179 6.43708 11.2787 5.39844 9.99983 5.39844ZM6.30566 7.7176C6.30566 5.68011 7.95044 4.02344 9.99983 4.02344C12.0387 4.02344 13.694 5.67874 13.694 7.7176V7.72149H13.694C13.6828 9.70916 12.1193 11.3345 10.1333 11.4022C10.0929 11.4036 10.0526 11.4014 10.0126 11.3957C10.0165 11.3963 10.0173 11.3962 10.0148 11.396C10.0124 11.3959 10.0085 11.3957 10.0033 11.3957C9.992 11.3957 9.9816 11.3965 9.9751 11.3973C9.93906 11.4018 9.90271 11.4034 9.86641 11.4022C7.88381 11.3346 6.30566 9.71178 6.30566 7.7176Z"
                    fill="#717375"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.8259 14.9717C5.11989 15.4472 4.72525 15.9914 4.57148 16.5085C6.04382 17.7414 7.93138 18.4793 9.99963 18.4793C12.0679 18.4793 13.9554 17.7414 15.4278 16.5085C15.2739 15.9911 14.879 15.4467 14.1724 14.9711C13.063 14.229 11.5545 13.8364 10.0065 13.8364C8.45914 13.8364 6.94538 14.2287 5.8259 14.9717ZM10.0065 12.4614C11.7627 12.4614 13.5539 12.9028 14.9378 13.8288L14.9393 13.8298C16.0417 14.5716 16.7431 15.5785 16.8616 16.6924C16.8847 16.9096 16.8033 17.1247 16.6423 17.2722C14.8889 18.8778 12.5616 19.8543 9.99963 19.8543C7.43764 19.8543 5.11034 18.8778 3.35699 17.2722C3.19596 17.1247 3.11455 16.9096 3.13765 16.6924C3.25616 15.5785 3.95754 14.5716 5.05998 13.8298L5.0634 13.8275L5.0634 13.8275C6.45542 12.9029 8.25007 12.4614 10.0065 12.4614Z"
                    fill="#717375"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99967 1.521C5.31676 1.521 1.52051 5.31725 1.52051 10.0002C1.52051 14.6831 5.31676 18.4793 9.99967 18.4793C14.6826 18.4793 18.4788 14.6831 18.4788 10.0002C18.4788 5.31725 14.6826 1.521 9.99967 1.521ZM0.145508 10.0002C0.145508 4.55786 4.55737 0.145996 9.99967 0.145996C15.442 0.145996 19.8538 4.55786 19.8538 10.0002C19.8538 15.4425 15.442 19.8543 9.99967 19.8543C4.55737 19.8543 0.145508 15.4425 0.145508 10.0002Z"
                    fill="#717375"
                  ></path>
                </svg>
              </div>
            )}

            {currentUser ? (
              <>
                <div className="divider w-[1px] bg-[#C2CED7] hidden md:flex"></div>
                <div
                  ref={userMenuRef}
                  className="userProfile cursor-pointer flex group"
                  onClick={toggleUserPanel}
                >
                  <div className="flex gap-2 items-center">
                    <div className="userPicture size-5 sm:size-5 lg:size-[30px]">
                      <img
                        src={userImage !== null ? userImage : userPlaceholder}
                        className="h-full w-full rounded-full"
                        alt="user profile pic"
                      />
                    </div>
                    <p className="userName text-primaryText text-base font-medium leading-6 tracking-[0.15px] hidden md:block max-w-[120px] truncate" title='MastersVideoLibrary Hoya Users'>
                      {userName}
                    </p>
                    <div className="userProfileMenuArrow hidden md:flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                      >
                        <path
                          d="M8.89979 0H1.24913C0.382767 0 -0.073698 1.02665 0.506668 1.66989L4.53333 6.13278C4.94446 6.58844 5.66522 6.56968 6.05209 6.09326L9.67609 1.63037C10.2068 0.97681 9.7417 0 8.89979 0Z"
                          fill="#8594A6"
                        />
                      </svg>
                    </div>
                  </div>

                  {isUserPanelOpen && <div className="overlay-popup"></div>}

                  {isUserPanelOpen && <UserPanel userPanelRef={userPanelRef} userPanelToggle={toggleUserPanel} logout={signOut} />}
                </div>
              </>
            ) : (
              <div className="accountActions gap-4 *:bg-primaryBlue *:text-white *:  *:leading-6 *:text-sm *:text-center *:rounded *:px-3 *:py-1 hidden sm:hidden md:flex">
                <Button
                  className="signInBtn font-bold tracking-[0.15px] whitespace-nowrap"
                  onClick={handleLogin}
                >
                  Login
                </Button>
                <Button
                  onClick={handleRegister}
                  className="registerBtn font-bold tracking-[0.15px]"
                >
                  Register
                </Button>
              </div>
            )}
            {!currentUser && isLoginPopupOpen && (
              <div ref={loginPopupRef} className="accountActionsForMobile block sm:block md:hidden  absolute right-0 z-50 top-full mt-4">
                <div className="bg-white min-w-[190px] rounded-lg shadow-accountActionsShadow px-6 py-4">
                  <ul className="space-y-[18px]">
                    <li className="flex signIn gap-3 cursor-pointer" onClick={openLoginModal}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.9997 5.97998C9.59543 5.97998 8.46973 7.1127 8.46973 8.50998C8.46973 9.85876 9.52336 10.9585 10.8584 11.0284C10.9504 11.0209 11.0484 11.0202 11.1437 11.0282C12.473 10.9572 13.521 9.86176 13.5297 8.50786C13.5286 7.11305 12.3948 5.97998 10.9997 5.97998ZM6.96973 8.50998C6.96973 6.28726 8.76402 4.47998 10.9997 4.47998C13.2239 4.47998 15.0297 6.28577 15.0297 8.50998V8.51422H15.0297C15.0175 10.6826 13.3119 12.4557 11.1453 12.5295C11.1013 12.531 11.0572 12.5287 11.0137 12.5224C11.018 12.5231 11.0188 12.523 11.016 12.5228C11.0135 12.5227 11.0092 12.5225 11.0035 12.5225C10.9912 12.5225 10.9798 12.5233 10.9728 12.5242C10.9334 12.5291 10.8938 12.5309 10.8542 12.5295C8.69134 12.4558 6.96973 10.6854 6.96973 8.50998Z"
                            fill="#717375"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.44661 16.4236C5.67642 16.9423 5.2459 17.5359 5.07815 18.1001C6.68434 19.4451 8.7435 20.2501 10.9998 20.2501C13.256 20.2501 15.3152 19.4451 16.9214 18.1001C16.7536 17.5357 16.3227 16.9418 15.5519 16.4228C14.3416 15.6134 12.696 15.1851 11.0073 15.1851C9.31923 15.1851 7.66787 15.613 6.44661 16.4236ZM11.0073 13.6851C12.9231 13.6851 14.8771 14.1666 16.3869 15.1767L16.3885 15.1778C17.5911 15.9871 18.3563 17.0855 18.4856 18.3007C18.5108 18.5376 18.4219 18.7723 18.2463 18.9332C16.3335 20.6847 13.7947 21.7501 10.9998 21.7501C8.20488 21.7501 5.66601 20.6847 3.75326 18.9332C3.57759 18.7723 3.48878 18.5376 3.51398 18.3007C3.64326 17.0855 4.4084 15.9871 5.61107 15.1778L5.61479 15.1753L5.6148 15.1753C7.13336 14.1666 9.09117 13.6851 11.0073 13.6851Z"
                            fill="#717375"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z"
                            fill="#717375"
                          />
                        </svg>
                      </span>
                      <span
                        className="text-primaryText font-medium tracking-[0.15px] text-base"
                      >
                        Login
                      </span>
                    </li>
                    <li className="flex signIn gap-3 cursor-pointer" onClick={openRegisterModal}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M12.5003 18.4582H7.50033C5.29522 18.4582 3.84067 17.9852 2.92784 17.0723C2.01501 16.1595 1.54199 14.7049 1.54199 12.4998V7.49984C1.54199 5.29473 2.01501 3.84018 2.92784 2.92735C3.84067 2.01452 5.29522 1.5415 7.50033 1.5415H9.16699C9.19714 1.5415 9.22842 1.55379 9.25406 1.57943C9.27971 1.60508 9.29199 1.63636 9.29199 1.6665C9.29199 1.69665 9.27971 1.72793 9.25406 1.75358C9.22842 1.77922 9.19714 1.7915 9.16699 1.7915H7.50033C5.54422 1.7915 4.06923 2.13424 3.10198 3.10149C2.13473 4.06874 1.79199 5.54373 1.79199 7.49984V12.4998C1.79199 14.4559 2.13473 15.9309 3.10198 16.8982C4.06923 17.8654 5.54422 18.2082 7.50033 18.2082H12.5003C14.4564 18.2082 15.9314 17.8654 16.8987 16.8982C17.8659 15.9309 18.2087 14.4559 18.2087 12.4998V10.8332C18.2087 10.803 18.2209 10.7717 18.2466 10.7461C18.2722 10.7205 18.3035 10.7082 18.3337 10.7082C18.3638 10.7082 18.3951 10.7205 18.4207 10.7461C18.4464 10.7717 18.4587 10.803 18.4587 10.8332V12.4998C18.4587 14.7049 17.9856 16.1595 17.0728 17.0723C16.16 17.9852 14.7054 18.4582 12.5003 18.4582Z"
                            fill="#292D32"
                            stroke="#717375"
                          />
                          <path
                            d="M5.76949 12.6724L5.76976 12.6705L6.1281 10.1622L6.12843 10.1598C6.17981 9.78723 6.44507 9.26162 6.71167 8.99502L13.2783 2.42836C14.0517 1.65503 14.7674 1.33105 15.4248 1.33105C16.0822 1.33105 16.7979 1.65503 17.5712 2.42836C18.4138 3.27094 18.7251 4.05752 18.6604 4.78582C18.6001 5.38175 18.2794 6.01255 17.5732 6.71096L17.5712 6.71292L11.0046 13.2796C10.738 13.5462 10.2124 13.8114 9.8398 13.8628L9.8374 13.8632L7.32907 14.2215L7.30805 14.2245L7.28735 14.2293C7.24303 14.2395 7.19071 14.2415 7.08311 14.2415C6.69731 14.2415 6.35962 14.1038 6.11782 13.8691C5.83692 13.5867 5.69785 13.1608 5.76949 12.6724ZM13.4561 2.60307L13.4546 2.60458L6.88789 9.17125L7.24145 9.5248L6.88789 9.17125C6.7549 9.30425 6.64117 9.48873 6.56053 9.64881C6.47985 9.80898 6.40029 10.0083 6.37226 10.1904L6.37223 10.1904L6.37147 10.1958L6.01313 12.7041L6.0128 12.7065C5.96677 13.0402 6.02222 13.421 6.29623 13.695C6.57023 13.969 6.95103 14.0245 7.28477 13.9785L7.28716 13.9781L9.79549 13.6198L9.7955 13.6198L9.80081 13.619C9.98165 13.5912 10.1823 13.5124 10.3442 13.4314C10.5011 13.3529 10.69 13.2385 10.8239 13.0994L17.3867 6.53669C17.9589 5.96451 18.34 5.37706 18.3977 4.75424L18.3977 4.7541C18.468 3.99221 18.0596 3.27805 17.389 2.59857L17.389 2.59856L17.3867 2.59625C16.6707 1.88025 15.9697 1.52607 15.2402 1.5936C14.5388 1.65851 13.9586 2.10482 13.4561 2.60307Z"
                            fill="#292D32"
                            stroke="#717375"
                          />
                          <path
                            d="M16.5413 7.69173C16.5357 7.69173 16.5311 7.69131 16.5283 7.6909L16.5215 7.68863L16.5101 7.68542C14.4867 7.11611 12.8765 5.50632 12.3065 3.48323C12.2891 3.418 12.3228 3.34734 12.396 3.32244C12.4642 3.30655 12.5254 3.34547 12.5425 3.40764L12.5425 3.40764L12.5434 3.41063C13.0906 5.35319 14.6298 6.89247 16.5724 7.43967L16.5724 7.43968L16.5754 7.4405C16.6335 7.45647 16.6801 7.52467 16.6592 7.60082L16.658 7.60545C16.6499 7.63586 16.6349 7.65498 16.6176 7.66787C16.5988 7.68183 16.5727 7.69173 16.5413 7.69173Z"
                            fill="#292D32"
                            stroke="#717375"
                          />
                        </svg>
                      </span>
                      <span
                        className="text-primaryText font-medium tracking-[0.15px] text-base"
                      >
                        Register
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}


          </div>
        </div>
      </div>
      {(location.pathname !== "/specialization" && location.pathname !== "/allTeamMembers" && location.pathname !== "/author-details") && (
        <div className="flex sm:gap-0 md:gap-6 justify-start min-[1025px]:justify-center min-[1025px]:max-w-[85%] max-w-full mx-auto">
          <div className="!shrink-0 xl:!shrink-0 2xl:!shrink-0  md:basis-[25%] lg:basis-[20%] xl:basis-[30%] !grow-0 hidden"></div>
          <div className="catagoryWrapper overflow-x-auto  relative sm:grow md:grow-0 basis-full md:basis-auto lg:basis-auto flex gap-1">
            <div className={`leftScrollIcon items-center h-full  rotate-90 size-7 cursor-pointer opacity-50 -ml-2 max-[1024px]:hidden ${leftscroll}`} onClick={() => handleNav(5, 200, -5)} >
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                <path d="M7.4 7.9999L6 9.3999L12 15.3999L18 9.3999L16.6 7.9999L12 12.5999L7.4 7.9999Z" fill="#666"></path>
              </svg>
            </div>
            <div
              className="catagoryHolder m-auto space-x-3 text-center whitespace-nowrap *:rounded-2xl *:text-sm *:font-normal *:text-[#fff] *:bg-[#7DA1C4] *:px-3 *:py-1 *:whitespace-nowrap *:cursor-pointer overflow-x-auto overflow-y-hidden flex"
              ref={categoryRef}
            >
              {categoryList && categoryList.map((details) =>
                <span className={'catagory lowercase first-letter:capitalize' + (activeCategory === details.tid ? ' active' : '')} key={details.tid} id={details.tid} onClick={handlerCategorySearch}>{details.name}</span>
              )}
            </div>
            <div className={`rightScrollIcon items-center h-full -rotate-90 size-7 cursor-pointer opacity-50 -mr-2 max-[1024px]:hidden ${rightscroll}`} onClick={() => handleNav(5, 200, +5)} >
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                <path d="M7.4 7.9999L6 9.3999L12 15.3999L18 9.3999L16.6 7.9999L12 12.5999L7.4 7.9999Z" fill="#666"></path>
              </svg>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;