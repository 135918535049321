import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getBannerDetails, resendEmailVerification } from "../../lib/api";
import BannerVideoPlayer from "../UI/BannerVideoPlayer";
import { useSelector } from 'react-redux';
import { useModal } from "../../store/ModalContext";
import LoadingSpinner from "../UI/LoadingSpinner";
import ResendEmail from "../Modals/ResendEmail";
import CountryUpdate from "../Modals/CountryUpdate";

const Banner = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [bannerData, setBannerData] = useState([]);
  const [bannerCount, setBannerCount] = useState(0);
  const { openRegisterModal, bodyBodyOverflow } = useModal();
  const [playVideo, setPlayVideo] = useState("");
  const [pauseVideo, setPauseVideo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [countryModel, setCountryModel] = useState(false);
  const [errorResend, setErrorResend] = useState(false);

  var autoplayVideo = true;
  var showIndicators = true;

  if (currentUser === null) {
    showIndicators = false;
  }

  useEffect(() => {
    sendRequest(currentUser);
    if (currentUser && currentUser.user_extra_details.country === null) {
      setCountryModel(true)
    }
  }, [currentUser]);

  const sendRequest = (currentUser) => {
    getBannerDetails(currentUser)
      .then((response) => {
        if (response) {
          setBannerCount(response.length);
          response.sort((a, b) => { return a.order > b.order ? 1 : -1 });
          setBannerData(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangec = (index, items) => {
    if (items.props.className === "videoOverlay") {
      setPlayVideo(items.props.children.props.useRef);
      setPauseVideo("");
    } else {
      setPauseVideo("Image");
    }
  };

  const onClickItemc = (index) => {
    console.log("onClickItemc", index);
    if (index === 0 && currentUser === null) {
      openRegisterModal();
    }
  };

  const onClickThumbc = () => {
    console.log("onClickThumbc");
  };

  const resendEmail = () => {
    setIsLoading(true)
    const data = {
      uid: currentUser.current_user.uid,
    }

    resendEmailVerification(currentUser.access_token, data).then((data) => {
      setIsLoading(false);
      setAlertModal(true);
      setSuccessMessage(data.message);
      setErrorResend(false)
    }).catch((error) => {
      setIsLoading(false);
      setAlertModal(true);
      setSuccessMessage(error.message);
      setErrorResend(true)
    })
  };


  return (
    <section className="videoSlider relative bannerVideos">
      {isLoading && (
        <div className="parentDisable">
          <LoadingSpinner />
        </div>
      )}
      <div className="videosHolder bg-[#006FBA]  h-auto overflow-hidden w-full relative">
        {currentUser ? (currentUser.user_extra_details.email_verified !== "1" ?
          (<div className="mailVerificationNotification flex gap-3 justify-center items-start bg-[orange]/80 py-2 px-5 *:text-sm absolute top-0 w-full z-10 !h-auto">
            <p className="text-white">Please verify your email to explore the the video library</p>
            <button className="resendBtn text-white cursor-pointer rounded-md border px-2" onClick={resendEmail}>Resend</button>
          </div>) : <></>) : <></>}

        {bannerCount > 0 ? (
          <Carousel
            key={bannerData.length}
            showArrows={false}
            showThumbs={false}
            onChange={onChangec}
            onClickItem={onClickItemc}
            onClickThumb={onClickThumbc}
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}
            stopOnHover={true}
            showIndicators={showIndicators}
            showStatus={false}
          >
            {bannerData.map((details) =>
              details.media_type === "Video" ? (
                <div key={details.uuid} className={'videoOverlay' + (currentUser === null ? ' controlsHide' : '')}>
                  <BannerVideoPlayer
                    key={details.uuid}
                    src={details.dam_asset_url}
                    type="video/mp4"
                    useRef={details.uuid}
                    playvideo_option={playVideo}
                    pausevideo_option={pauseVideo}
                    autoplayvideo_option={autoplayVideo}
                  />
                </div>
              ) : (
                <div key={details.uuid} className="imageOverlay">
                  <img alt="" src={details.dam_asset_url} />
                  <div className="overlayContent absolute top-0 bottom-0 h-full flex justify-center px-5 lg:px-[56px] max-w-full sm:max-w-[85%] md:max-w-[65%] lg:max-w-[60%] xl:max-w-[45%]">
                    <div className="flex flex-col gap-3 sm:gap-4 h-full justify-center">
                      <h1 className="welcomeText text-2xl leading-6 sm:text-[40px] font-bold sm:leading-[48px] text-white text-left">{details.title}</h1>
                      <p className="text-[#E4ECF2] text-base leading-6 sm:text-[28px] font-normal sm:leading-10 tracking-[0.1px] text-left">{details.description}</p>
                      {details.redirect_url ?
                        <a href={details.redirect_url} className="btn browseCourses bg-primaryBlue text-white rounded px-[10px] py-1 sm:px-4 sm:py-[10px] text-sm font-bold leading-5 sm:text-base sm:leading-5  cursor-pointer mr-auto" target="_blank" rel="noreferrer">Click Here</a>
                        : <div></div>}
                    </div>
                  </div>
                </div>
              )
            )}
          </Carousel>
        ) : (
          <span></span>
        )}
      </div>
      {alertModal && <ResendEmail onClose={() => { setAlertModal(false); bodyBodyOverflow() }} successMessage={successMessage} errorResend={errorResend} />}
      {countryModel && <CountryUpdate onClose={() => { setCountryModel(false); bodyBodyOverflow() }} successMessage={successMessage} currentUser={currentUser} setIsLoading={setIsLoading} />}

    </section>
  );
};

export default Banner;
