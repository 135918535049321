import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { getCountry, updateUserProfile } from "../../lib/api";
import Dropdown from "../UI/Dropdown";
import Modal from "../UI/Modal";
import Button from '../UI/Button';
import { updateCountry } from '../../redux/userSlice';


const CountryUpdate = (props) => {
    const [countryOptionsList, setCountryOptionsList] = useState([]);
    const [selectedCountryOption, setSelectedCountryOption] = useState(null);
    const [isCountryValid, setCountryValid] = useState(false);
    const dispatch = useDispatch();

    const countrysendRequest = () => {
        getCountry()
            .then((response) => {
                const options = response?.data.map((details) => ({
                    id: details.id,
                    value: details.country_name,
                }));
                setCountryOptionsList(options);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCountryOption = (option) => {
        setSelectedCountryOption(option);
        setCountryValid(true);
    };

    const handleBlurCountry = (isValid) => {
        setCountryValid(isValid);
    };

    let formIsValid = false;

    if (isCountryValid) {
        formIsValid = true;
    }

    const closeModal = () => {
        props.onClose();
        dispatch(updateCountry('-'));

    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }
        props.setIsLoading(true);
        props.onClose();
        event.preventDefault();
        const formData = {
            field_country: {
                value: selectedCountryOption.value,
            }
        };
        dispatch(updateCountry(selectedCountryOption.value));
        updateUserProfile(props.currentUser.access_token, props.currentUser.current_user.uid, formData)
            .then(async (res) => {
                props.setIsLoading(false);
            })
    }

    useEffect(() => {
        countrysendRequest();
    }, []);

    return (
        <Modal>
            <div className="succesMsgHolder h-full flex items-center grow mx-4 sm:mx-4 md:mx-4">
                <form className="registerForm grow static z-50" onSubmit={submitHandler}>
                    <div className="flex items-stretch justify-normal rounded-lg sm:rounded-lg max-w-full sm:max-w-[400px] max-h-full sm:max-h-full h-[320px] sm:max-h-auto  m-auto bg-white relative px-10 py-8 grow">
                        <div className="w-full flex flex-col justify-center items-center gap-5">
                            Select Country
                            <div className='w-full'>
                                <Dropdown
                                    label="Country"
                                    type="country"
                                    labelClass="countryLabel text-[#333] text-sm font-normal leading-5"
                                    placeholder="Select a Country"
                                    isManditory={true}
                                    options={countryOptionsList}
                                    onSelect={handleCountryOption}
                                    onBlur={(isValid) => handleBlurCountry(isValid)}
                                    isValid={isCountryValid} // Pass validation state for dropdown 1
                                    selectedValue={selectedCountryOption}
                                />
                                <Button
                                    disabled={!formIsValid}
                                    type="submit"
                                    // onClick={props.handleGdprConsent}
                                    className={!formIsValid ? "w-full btn mt-8 continueBtn  bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center disabled" : "btn mt-8 continueBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center cursor-pointer"}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                        <div
                            className="closeIconHolder absolute left-10 sm:right-10 sm:left-auto top-5 cursor-pointer sm:landscape: right-10"
                            onClick={closeModal}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                                    fill="#717375"
                                    stroke="#717375"
                                />
                                <path
                                    d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                                    fill="#717375"
                                    stroke="#717375"
                                />
                            </svg>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default CountryUpdate;
