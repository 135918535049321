import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Banner from '../Layout/Banner';
import RecentlyWatchedVideos from '../Layout/RecentlyWatchedVideos';
import RecommededVideos from '../Layout/RecommendedVideos';
import FeatureVideos from '../Layout/FeatureVideos';
import NewVideos from '../Layout/NewVideos';
import TeamMembers from '../Layout/TeamMembers';
import PeersSearch from '../Layout/PeersSearch';
import ExploreOurVideos from '../Layout/ExploreOurVideos';
import OphthalmicPrecision from '../Layout/OphthalmicPrecision';
import TalkToUs from '../Layout/TalkToUs';
import EmailVerification from '../Layout/EmailVerification';

const StartingPageContent = () => {

  const { currentUser } = useSelector((state) => state.user);
  const { afterHashKeyword } = useSelector((state) => state.orientation);

  useEffect(() => {
    if (afterHashKeyword) {
      const sectionId = afterHashKeyword.split('/');
      const element = document.getElementById(sectionId[1]);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // console.log(currentUser.user_extra_details)
  }, [afterHashKeyword]);

  return (
    <Fragment>
      {/* {currentUser ? (currentUser.user_extra_details.email_verified !== "1" ? <EmailVerification /> : <></>) : <></>} */}
      <Banner />
      {currentUser ? <RecentlyWatchedVideos /> : <></>}
      {currentUser ? <></> : <FeatureVideos />}
      <NewVideos />
      {currentUser ? <RecommededVideos /> : <></>}
      <TeamMembers />
      <PeersSearch />
      <ExploreOurVideos />
      <OphthalmicPrecision />
      <TalkToUs />
    </Fragment>
  );
};

export default StartingPageContent;
