import React, { createContext, useState, useContext } from 'react';

import LoginForm from '../components/Modals/LoginForm';
import ForgotPasswordForm from '../components/Modals/ForgotPasswordForm';
import RegisterForm from '../components/Modals/RegisterForm';
import GdprConsent from '../components/Modals/GdprConsent';
import ForgotPasswordConfirmationMessage from '../components/Modals/ForgotPasswordConfirmation';
import RegisterConfirmationMessage from '../components/Modals/RegisterConfirmation';
import ResetPasswordForm from '../components/Modals/ResetPassword';
import ResetPasswordConfirmationModal from '../components/Modals/ResetPasswordConfirmation';
import BannerVideo from '../components/Modals/BannerVideo';
import ContactUs from '../components/Modals/ContactUs';
import VideoSubmit from '../components/Modals/VideoSubmit';
import SendQuery from '../components/Modals/SendQuery';
import ThankYou from '../components/Modals/ThankYou';
import { AES, enc } from 'crypto-js';
import Feedback from '../components/Modals/Feedback';
import ChangePassword from '../components/Modals/ChangePassword';
import Endfeedback from '../components/Modals/EndFeedback';
import EndThankYou from '../components/Modals/EndThankYou';
import SupportThankYou from '../components/Modals/SupportThankYou';


// Create the context
const ModalContext = createContext();

// Create a provider component
export const ModalProvider = ({ children }) => {

  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [isGdprConsent, setIsGdprConsent] = useState(false);
  const [isConfirmationMessage, setIsConfirmationMessage] = useState(false);
  const [isRegisterConfirmationMessage, setIsRegisterConfirmationMessage] = useState(false);
  const [isResetPasswordConfirmation, setIsResetPasswordConfirmation] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isBannerVideo, setIsBannerVideo] = useState('');
  const [isContactUs, setIsContactUs] = useState('');
  const [isVideoSubmit, setIsVideoSubmit] = useState('');
  const [isSendQuery, setIsSendQuery] = useState('');
  const [isChangePassword, setIsChangePassword] = useState('');
  const [isSupportRequestSubmitted, setIsSupportRequestSubmitted] = useState(false);
  const [isSupportRequestEndSubmitted, setIsSupportRequestEndSubmitted] = useState(false);
  const [isSupportResponse, setIsSupportResponse] = useState(false);
  const [isFeedback, setIsFeedback] = useState('');
  const [isEndFeedback, setIsEndFeedback] = useState('');
  const [videoId, setVideoId] = useState('');
  const SecretKey = 'BJCWLkV7Kjwuvx8lrC';

  // Encryption
  const EncryptionData = (data) => {
    var b64 = AES.encrypt(data, SecretKey).toString();
    var e64 = enc.Base64.parse(b64);
    var cipherText = e64.toString(enc.Hex);
    return cipherText;
  }

  // Decryption
  const DecryptionData = (data) => {
    var reb64 = enc.Hex.parse(data);
    var bytes = reb64.toString(enc.Base64);
    var decrypt = AES.decrypt(bytes, SecretKey);
    var originalText = decrypt.toString(enc.Utf8);
    return originalText;
  }

  const secondsToMinutes = (seconds) => {
    var h = Math.floor(seconds / (60 * 60));
    var divisor_for_minutes = seconds % (60 * 60);
    var m = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var s = (Math.ceil(divisor_for_seconds) < 10 ? '0' : '') + Math.ceil(divisor_for_seconds);
    return `${h ? `${h}:` : ""}${m ? `${m}:${s}` : `${s}s`}`
  }

  const openLoginModal = () => {
    setIsLoginClicked(true);
    setIsForgotPassword(false);
  }

  const closeLoginModal = () => {
    setIsLoginClicked(false);
    bodyBodyOverflow();
  }

  const openForgotPasswordModal = () => {
    setIsForgotPassword(true);
    setIsLoginClicked(false);
  }

  const openResetPasswordModal = () => {
    setIsConfirmationMessage(false);
    setIsResetPassword(true);
  }

  const closeResetPasswordModal = () => {
    setIsResetPassword(false);
    bodyBodyOverflow();
  }

  const closeForgotPasswordModal = () => {
    setIsForgotPassword(false);
    bodyBodyOverflow();
  }

  const openRegisterModal = () => {
    setIsLoginClicked(false);
    setIsRegister(true);
  }
  const closeRegisterModal = () => {
    setIsLoginClicked(false);
    setIsRegister(false);
    bodyBodyOverflow();
  }

  const openGdprConsentModal = () => {
    setIsRegister(false);
    setIsGdprConsent(true);
  }
  const closeGdprConsentModal = () => {
    setIsGdprConsent(false);
    bodyBodyOverflow();
  }

  const openConfirmMessageModal = () => {
    setIsForgotPassword(false);
    setIsConfirmationMessage(true);
  }

  const closeConfirmMessageModal = () => {
    setIsConfirmationMessage(false);
    bodyBodyOverflow();
  }

  const openRegisterConfirmMessageModal = () => {
    setIsRegister(false);
    setIsRegisterConfirmationMessage(true);
  }

  const closeRegisterConfirmMessageModal = () => {
    setIsRegisterConfirmationMessage(false);
    setIsLoginClicked(true);
    bodyBodyOverflow();
  }

  const openResetPasswordConfirmMessageModal = () => {
    setIsResetPassword(false);
    setIsResetPasswordConfirmation(true);
  }

  const closeResetPasswordConfirmMessageModal = () => {
    setIsResetPasswordConfirmation(false);
    bodyBodyOverflow();
  }

  const openBannerVideoModal = () => {
    setIsBannerVideo(true);
  }

  const closeBannerVideoModal = () => {
    setIsBannerVideo(false);
    bodyBodyOverflow();
  }

  const openContactUsModal = () => {
    setIsContactUs(true);
  }

  const closeContactUsModal = () => {
    setIsContactUs(false);
    bodyBodyOverflow();
  }

  const openVideoSubmitModal = () => {
    setIsVideoSubmit(true);
  }

  const closeVideoSubmitModal = () => {
    setIsVideoSubmit(false);
    bodyBodyOverflow();
  }

  const openSendQueryModal = (id) => {
    setVideoId(id)
    setIsSendQuery(true);
  }

  const closeSendQueryModal = () => {
    setIsSendQuery(false);
    bodyBodyOverflow();
  }

  const openChangePasswordModal = () => {
    setIsChangePassword(true);
  }

  const closeChangePasswordModal = () => {
    setIsChangePassword(false);
    bodyBodyOverflow();
  }


  const openThankYouModal = () => {
    setIsSupportRequestSubmitted(true);
  }

  const closeThankYouModal = () => {
    setIsSupportRequestSubmitted(false);
    bodyBodyOverflow();
  }
  const openFeedbackModal = () => {
    setIsFeedback(true);
  }

  const closeFeedbackModal = () => {
    setIsFeedback(false);
    bodyBodyOverflow();
  }

  const openEndThankYouModal = () => {
    setIsSupportRequestEndSubmitted(true);
  }

  const closeEndThankYouModal = () => {
    setIsSupportRequestEndSubmitted(false);
    bodyBodyOverflow();
  }

  const openEndFeedbackModal = () => {
    setIsEndFeedback(true);
  }

  const closeEndFeedbackModal = () => {
    setIsEndFeedback(false);
    bodyBodyOverflow();
  }

  const openSupportThankYouModal = () => {
    setIsSupportResponse(true);
  }

  const closeSupportThankYouModal = () => {
    setIsSupportResponse(false);
    bodyBodyOverflow();
  }

  const bodyBodyOverflow = () => {
    document.body.classList.remove('h-screen');
    document.body.classList.remove('overflow-hidden');
  }

  return (
    <ModalContext.Provider value={{ isLoginClicked, openLoginModal, closeLoginModal, isRegister, openRegisterModal, closeRegisterModal, isBannerVideo, openBannerVideoModal, closeBannerVideoModal, isContactUs, openContactUsModal, closeContactUsModal, isVideoSubmit, openVideoSubmitModal, closeVideoSubmitModal, openThankYouModal, closeThankYouModal, openFeedbackModal, closeFeedbackModal, openEndThankYouModal, closeEndThankYouModal, openEndFeedbackModal, closeEndFeedbackModal, openSendQueryModal, closeSendQueryModal, openChangePasswordModal, closeChangePasswordModal, EncryptionData, DecryptionData, secondsToMinutes, bodyBodyOverflow, openRegisterConfirmMessageModal, openSupportThankYouModal, closeSupportThankYouModal }}>
      {children}
      {isLoginClicked && (
        <LoginForm
          onClose={closeLoginModal}
          handleForgotPassword={openForgotPasswordModal}
          handleRegister={openRegisterModal}
        />
      )}

      {isForgotPassword && (
        <ForgotPasswordForm
          onClose={closeForgotPasswordModal}
          backToLogin={openLoginModal}
          confirmEmail={openConfirmMessageModal}
        />
      )}

      {isResetPassword && (
        <ResetPasswordForm
          onClose={closeResetPasswordModal}
          confirmResetPassword={openResetPasswordConfirmMessageModal}
        />
      )}

      {isResetPasswordConfirmation && (
        <ResetPasswordConfirmationModal
          onClose={closeResetPasswordConfirmMessageModal}
          backToLogin={openLoginModal}
        />
      )}

      {isRegister && (
        <RegisterForm
          onClose={closeRegisterModal}
          backToLogin={openLoginModal}
          handleGdprConsent={openGdprConsentModal}
        />
      )}

      {isGdprConsent && (
        <GdprConsent
          onClose={closeGdprConsentModal}
          backToLogin={openGdprConsentModal}
          openRegisterConfirmModal={openRegisterConfirmMessageModal}
        />
      )}

      {isConfirmationMessage && (
        <ForgotPasswordConfirmationMessage
          onClose={closeConfirmMessageModal}
          openResetPassword={openResetPasswordModal}
        />
      )}

      {isRegisterConfirmationMessage && (
        <RegisterConfirmationMessage
          onClose={closeRegisterConfirmMessageModal}
        />
      )}

      {isBannerVideo && (
        <BannerVideo
          onClose={closeBannerVideoModal}
          handleRegister={openBannerVideoModal}
        />
      )}

      {isContactUs && (
        <ContactUs
          onClose={closeContactUsModal}
          handleRegister={openContactUsModal}
        />
      )}

      {isVideoSubmit && (
        <VideoSubmit
          onClose={closeVideoSubmitModal}
          handleRegister={openVideoSubmitModal}
        />
      )}

      {isSendQuery && (
        <SendQuery
          onClose={closeSendQueryModal}
          videoId={videoId}
        />
      )}

      {isChangePassword && (
        <ChangePassword
          onClose={closeChangePasswordModal}
          handleSendQuery={openChangePasswordModal}
        />
      )}

      {isSupportRequestSubmitted && (
        <ThankYou
          onClose={closeThankYouModal}
        />
      )}

      {isSupportRequestEndSubmitted && (
        <EndThankYou
          onClose={closeEndThankYouModal}
        />
      )}

      {isFeedback && (
        <Feedback
          onClose={closeFeedbackModal}
        />
      )}

      {isEndFeedback && (
        <Endfeedback
          onClose={closeEndFeedbackModal}
        />
      )}

      {isSupportResponse && (
        <SupportThankYou
          onClose={closeSupportThankYouModal}
        />
      )}

    </ModalContext.Provider>
  );
};

// Custom hook to consume the context
export const useModal = () => useContext(ModalContext);