import { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { ModalProvider } from './store/ModalContext';

import { checkOrientation } from './redux/orientationSlice';
import { checkMobile, checkTab, checkDesktop } from './redux/deviceTypeSlice';
import NotFound from './pages/NotFound';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage';
import UserProfile from './components/Profile/UserProfile';
import UserSettings from './components/Settings/UserSettings';
import SearchResultPage from './pages/SearchResultPage';
import SearchAllResultPage from './pages/SearchAllResultPage';
import VideoDetailsPage from './pages/VideoDetailsPage';
import SpecializationPage from './pages/SpecializationPage';
import FooterLinkData from './components/FooterLinks/FooterLinkData'
import Author from './pages/AuthorsListPage';
import AuthorDetails from './components/TeamMembers/AuthorDetail';
import MyFavourites from './components/MyFavourites/MyFavourites';
import WatchLater from './components/WatchLater/WatchLater';
import History from './components/History/History';
import SupportPage from './pages/SupportPage';
import MiniSearch from './components/MiniSearch/MiniSearch';
import NotificationDetails from './components/NotificationDetail/NotificationDetails';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ResetNewPassword from './components/ResetNewPassword/ResetNewPassword';
import UserActivation from './components/UserActivation/UserActivation';

function App() {
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {

    const userAgent = navigator.userAgent;

    const isMobile = /iPhone|iPod|Android/i.test(userAgent);
    const isTablet = /iPad|(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile) {
      dispatch(checkMobile());
    } else if (isTablet) {
      dispatch(checkTab());
    } else {
      dispatch(checkDesktop())
    }

    if (window.screen?.orientation) {
      if (window.screen.orientation.type === 'landscape-primary') {
        dispatch(checkOrientation('landscape'));
      } else if (window.screen.orientation.type === 'portrait-primary') {
        dispatch(checkOrientation('portrait'));
      }
    } else {
      dispatch(checkOrientation('portrait'));
    }

    const checkingOrientation = () => {
      if (window.screen?.orientation) {
        if (window.screen.orientation.type === 'landscape-primary') {
          dispatch(checkOrientation('landscape'));
        } else if (window.screen.orientation.type === 'portrait-primary') {
          dispatch(checkOrientation('portrait'));
        }
      } else {
        dispatch(checkOrientation('portrait'))
      }
    };

    window.addEventListener('resize', checkingOrientation);

    return () => {
      window.removeEventListener('resize', checkingOrientation);
    };
  }, [dispatch])

  return (
    <BrowserRouter>
      <ModalProvider>
        <Layout>
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>

            <Route path="/search-result/:id">
              <SearchResultPage />
            </Route>

            <Route path="/searchall-result/:id">
              <SearchAllResultPage />
            </Route>

            <Route path="/minisearch">
              <MiniSearch />
            </Route>

            <Route path="/resetpassword">
              {currentUser && <Redirect to='/' />}
              {!currentUser && <ResetPassword />}
            </Route>

            <Route path="/resetnewpassword">
              {currentUser && <Redirect to='/' />}
              {!currentUser && <ResetNewPassword />}
            </Route>

            <Route path="/useractivation">
              <UserActivation />
            </Route>

            <Route path="/specialization">
              {currentUser && <SpecializationPage />}
              {!currentUser && <Redirect to='/' />}
            </Route>

            <Route path="/video-details/:name">
              <VideoDetailsPage preload="true" />
            </Route>

            <Route path="/profile">
              {currentUser && <UserProfile />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/settings">
              {currentUser && <UserSettings />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/support">
              {currentUser && <SupportPage />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/allTeamMembers">
              <Author />
            </Route>

            <Route path="/author-details/:authorid">
              <AuthorDetails />
            </Route>

            <Route path="/notificationDetails/:notificationid">
              {currentUser && <NotificationDetails />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/myfavourites">
              {currentUser && <MyFavourites />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/watchlater">
              {currentUser && <WatchLater />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/history">
              {currentUser && <History />}
              {!currentUser && <Redirect to="/" />}
            </Route>

            <Route path="/:linkname">
              <FooterLinkData />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
