import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorList } from "../../lib/api";
import LoadingSpinner from "../UI/LoadingSpinner";
import { updateAuthorList } from "../../redux/authorSlice";
import { API_ORIGIN } from "../../utils/Environment";
import { useModal } from '../../store/ModalContext';

const AuthorList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { authorList } = useSelector((state) => state.author);
    const [isLoading, setIsLoading] = useState(false);
    const { EncryptionData } = useModal();

    const handleAuthorDetails = (authorName) => {
        let transformedValue = authorName
            .toLowerCase()
            .replace(/\./g, "")
            .replace(/\s+/g, "-");

        history.push('/author-details/' + transformedValue);
    }

    const getAuthorListDetails = useCallback(() => {
        setIsLoading(true)
        getAuthorList()
            .then((response) => {
                setIsLoading(false)
                response.sort((a, b) => { return a.order > b.order ? 1 : -1 });
                dispatch(updateAuthorList(response));
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);
            })
    }, [dispatch])

    useEffect(() => {
        if (authorList === null || authorList.length === 0) {
            getAuthorListDetails();
        }
    }, [getAuthorListDetails, authorList])

    return (
        <div className="authorsListSection flex grow">
            {isLoading && (
                <div className="parentDisable">
                    <LoadingSpinner />
                </div>
            )}
            <div className={location.pathname === "/allTeamMembers" ? "bg-[#F4F8FD] px-5 sm:px-5 md:px-5 lg:px-14 pt-5 sm:pt-5 md:pt-5 lg:pt-10 pb-10 sm:pb-20 w-full" : "overflow-auto"}>
                {location.pathname === "/allTeamMembers" && <div className="flex justify-between items-start gap-x-6 gap-y-4">
                    <div>
                        <h2 className="text-[#333] text-lg barlowBold leading-5 sm:text-[28px] sm:leading-9 sm:tracking-[-0.25px]">Masters</h2>
                        <p className="text-sm sm:text-base leading-5 sm:leading-6 font-normal text-[#333] mt-1 sm:mt-[2px]">Learn from the below Masters along with over 300 contributing experts</p>
                    </div>
                </div>}
                <div className={location.pathname === "/allTeamMembers" ? "gap-4 sm:gap-6 whitespace-nowrap  authorsListGrid sm:mt-6 mt-4" : "flex flex-nowrap gap-4 sm:gap-6 overflow-x-auto whitespace-nowrap authors *:basis-auto md:*:basis-1/6 sm:mt-6 mt-4"}>
                    {authorList && authorList.map((author, index) =>
                        <figure className="group cursor-pointer" key={index}>
                            <div className="flex flex-col gap-3" onClick={() => handleAuthorDetails(author.name)}>
                                <div className="authorPicHolder author">
                                    <img src={`${API_ORIGIN}/${author.image}`} className="rounded-lg grayscale group-hover:grayscale-0" alt={author.name} />
                                </div>
                                <figcaption className="text-center">
                                    <p className="text-[#333] font-medium text-sm leading-6 md:text-base md:leading-6">{author.name}</p>
                                    <p className="designation text-[#717375] font-medium text-xs md:text-sm leading-5 tracking-[0.03px] md:leading-5 sm:tracking-[0.035px]">{author.specialty}</p>
                                </figcaption>
                            </div>
                        </figure>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuthorList;